"use client";
import React, { useState } from "react";
import Image from "next/image";
import Link from "next/link";

const Services = () => {
    const [hoveredId, setHoveredId] = useState(null);

    const handleMouseEnter = (id) => {
        setHoveredId(id);
    };

    const handleMouseLeave = () => {
        setHoveredId(null);
    };

    const ServicesData = [
        {
            id: 1,
            title: "AI ML\nDevelopment",
            image: "/Img/Home/services/aiml.png",
            link: "/services/ai-ml",
        },
        {
            id: 2,
            title: "Blockchain\nServices",
            image: "/Img/Home/services/blockchain.png",
            link: "/blockchain/blockchain-development-services",
        },
        
        {
            id: 3,
            title: "Software\nDevelopment",
            image: "/Img/Home/services/software.png",
            link: "services/software-development",
        },
        {
            id: 4,
            title: "Digital\nTransformation",
            image: "/Img/Home/services/digital.png",
            link: "/scale/digital-transformation",
        },
        {
            id: 5,
            title: "Data Science\n& Analytics",
            image: "/Img/Home/services/datascience.png",
            link: "/services/data-science-analytics",
        },
        {
            id: 6,
            title: "Cloud\nServices",
            image: "/Img/Home/services/cloud.png",
            link: "/services/cloud",
        },
        {
            id: 7,
            title: "Mobile App\nDevelopment",
            image: "/Img/Home/services/mobile.png",
            link: "/services/mobile-app-development",
        },
       
        {
            id: 8,
            title: "Product Design\nand Ideation",
            image: "/Img/Home/services/productdesign.png",
            link: "https://www.tanthetaa.com/product-management",
        },
    ];

    return (
        <div className="container mx-auto">
            <div className="text-black font-bold lg:text-3xl mx-10 text-center flex flex-col items-center md:mx-20 md:text-2xl text-md !leading-normal">
            Revolutionizing businesses with solutions that drive innovation
            </div>
            <div className="grid lg:grid-cols-4 md:grid-cols-2 md:grid-rows-4 lg:grid-rows-2 gap-5 mx-10 xl:mx-60 lg:mx-40 md:mx-32 mt-5 p-5 xl:h-[500px]">
                {ServicesData.map((service) => (
                    <div
                        key={service.id}
                        className={`p-5 rounded-2xl shadow-lg my-1 md:h-auto grid grid-rows-2 transition-all duration-[1500ms] ease-in-out`}
                        onMouseEnter={() => handleMouseEnter(service.id)}
                        onMouseLeave={handleMouseLeave}
                        style={{
                            boxShadow: "0px 0px 38px 0px #7B3FE429",
                            background:
                                hoveredId === service.id
                                    ? "linear-gradient(98.46deg, #410EAD -1.13%, #7B3FE4 98.96%)"
                                    : "white",
                            transition: "background 1.5s ease-in-out", // Slow background color transition
                        }}
                    >
                        <div
                            className={`mb-2 transition-all duration-700 ease-in-out ${
                                hoveredId === service.id ? "mt-3" : "mt-5"
                            }`}
                        >
                            <Image
                                src={service.image}
                                width={50}
                                height={50}
                                alt={service.title}
                                style={{
                                    filter:
                                        hoveredId === service.id
                                            ? "brightness(0) invert(1)"
                                            : "brightness(0%)",
                                    transition: "filter 0.7s ease-in-out",
                                }}
                            />
                        </div>
                        <div
                            className={`mt-5 font-bold transition-colors duration-700 ease-in-out ${
                                hoveredId === service.id
                                    ? "text-white"
                                    : "text-[#410EAD]"
                            }`}
                            style={{ whiteSpace: "pre-line" }}
                        >
                            {service.title}
                        </div>
                        {hoveredId === service.id && (
                            <Link href={service.link}>
                                <button className="rounded-md text-white w-fit px-2 py-1 mt-2 border border-white text-sm transition-opacity duration-700 ease-in-out">
                                    Know More &rarr;
                                </button>
                            </Link>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Services;
