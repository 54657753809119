"use client";
import React, { useState } from "react";
import { HiOutlineMenu } from "react-icons/hi";
import { cx } from "class-variance-authority";

import { Ethereum, Polygon } from "@/public/Img/Home";

import {
    CioReview,
    Clutch,
    Cmmi,
    sdk_1,
    sdk_2,
    sdk_3,
    sdk_4,
    TanThetaChart,
    TanThetaChartMobile,
    HandDrawnNftStyleApeIllustration,
} from "@/public/Img/Home";
import Image from "next/image";
import { AnimatedBeamMultipleOutputDemo } from "./Animated Beam/AnimatedBeamMultipleOutputDemo";
import { AnimatedBeamMultipleHorizontal } from "./Animated Beam/AnimatedBeamMultipleHorizontal";
import CodeSnippet from "./CodeSnippet";
import {
    AIML,
    CrossBridge,
    DApps,
    DEFI,
    DEX,
    ERC4337,
    ERC6551,
    ERC7702,
    JSONRPC,
    NFT,
    SHA256,
    Solana,
    SPHINICS,
    SWAP,
    UserOps,
    WalletDevelopment,
} from "./codeSnippets";

export const dynamic = "force-dynamic";

const DigitalProductDevelopment = () => {
    const DigitalProductDevelopmentCard = [
        {
            id: 1,
            cardImage: CioReview,
            cardTitle:
                "The most promising mobile apps solution providers globally",
            cardSubTitle:
                "We have been recognized as a market leader globally, post a rigorous evaluation of factors such as company`s ability to deliver, market presence & client experience",
        },
        {
            id: 2,
            cardImage: Clutch,
            cardTitle:
                "We are ranked among the top global leaders in mobile app development",
            cardSubTitle:
                "Beside being recognised as the top global leader in custom software development, we have been awarded #1 app development company for startup and Fintech by Clutch for 2019.",
        },
        {
            id: 3,
            cardImage: Cmmi,
            cardTitle: "Setting a new benchmark in quality of service",
            cardSubTitle:
                "We have achieved Level 3 of Capability Maturity Model Integration (CMMI), setting a new benchmark to offer the best technical solutions to clients.",
        },
    ];

    const [Active, setActive] = useState(1);

    const Warehousing = (id) => {
        setActive(id);
    };

    const buttonCommonCss =
        "ease-in-out duration-700 shadow shadow-[#00000040] font-normal text-sm lg:text-base px-4 md:px-5 py-2 rounded-full mr-3 md:my-2 my-1 font-manrope no-underline";

    const inActiveColor = `bg-white hover:bg-[#410EAD] text-[#410EAD] hover:text-white border hover:border-[#410EAD] ${buttonCommonCss}`;

    const activeColor = `bg-[#410EAD] border-[#410EAD] text-white ${buttonCommonCss}`;

    const buttonData = [
        { id: 1, name: "NFT" },
        { id: 2, name: "DEFI" },
        { id: 3, name: "Wallet Development" },
        { id: 4, name: "User Ops" },
        { id: 5, name: "DApps" },
        { id: 6, name: "Solana" },
        { id: 7, name: "ERC 4337" },
        { id: 8, name: "ERC 6551" },
        { id: 9, name: "ERC 7702" },
        { id: 10, name: "JSON-RPC" },
        { id: 11, name: "SWAP" },
        { id: 12, name: "Cross-Bridge" },
        { id: 13, name: "AI/ML" },
        { id: 14, name: "DEX" },
        { id: 15, name: "SPHINICS+" },
        { id: 16, name: "SHA-256" },
    ];

    // const warehousingSdk = () => {
    //     return (
    //         <>
    //             <div className="flex justify-end items-center p-2 lg:p-4 border-b-2">
    //                 <button className="text-[#898989] font-medium">
    //                     Browse
    //                 </button>
    //                 <button className="text-[#898989] px-3 lg:px-5 font-medium">
    //                     Collections
    //                 </button>
    //                 <button className="text-[#898989] pr-3 lg:pr-5 font-medium">
    //                     Create
    //                 </button>
    //                 <button className="text-[#898989] font-medium">
    //                     <HiOutlineMenu className="text-3xl text-[#A577F6]" />
    //                 </button>
    //             </div>
    //         </>
    //     );
    // };

    return (
        <>
            <section className="my-20 ">
                <h4 className="text-2xl sm:text-3xl xl:text-4xl md:leading-[52px] leading-8 font-bold font-manrope text-center container mx-auto px-4 md:px-24 lg:px-24 xl:px-40">
                    Lead in blockchain innovation by never giving up as you
                    deliver groundbreaking digital services with us.
                    {/* <span className="lg:block font-manrope leading-[52px]">
                        while creating&nbsp;
                        <span className="text-[#410EAD] font-manrope leading-[52px]">
                            digital products wit us.
                        </span>
                    </span> */}
                </h4>

                {/*
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-12 mt-[50px]">
                    {DigitalProductDevelopmentCard.map((element) => {
                        return (
                            <div
                                className="text-center border border-[#D0D0DD] px-6 lg:px-12 py-6 lg:py-8"
                                key={element.id}
                            >
                                <div className="flex justify-center mb-3">
                                    <Image
                                        src={element.cardImage}
                                        alt={element.cardImage}
                                        className=""
                                    />
                                </div>
                                <h4 className="text-[17px] leading-[21px] font-semibold mb-3">
                                    {element.cardTitle}
                                </h4>
                                <h5 className="text-[#59587C]">
                                    {element.cardSubTitle}
                                </h5>
                            </div>
                        );
                    })}
                </div> */}

                {/* <div className="md:block hidden">
                    <AnimatedBeamMultipleOutputDemo />
                </div>
                <div className="md:hidden block">
                    <AnimatedBeamMultipleHorizontal />
                </div> */}

                <div className="my-20 lg:my-[140px]">
                    <div className="flex justify-center">
                        <Image
                            src={TanThetaChart}
                            alt="TanThetaa Chart"
                            className="hidden md:block"
                        />
                        <Image
                            src={TanThetaChartMobile}
                            alt="TanThetaa Chart"
                            className="block md:hidden"
                        />
                    </div>
                </div>

                <div className="container mx-auto px-4 md:px-24 lg:px-24 xl:px-0 z-50">
                    <div className="grid lg:grid-cols-2 gap-2">
                        <div className="container my-auto">
                            <h2 className="text-2xl sm:text-3xl xl:text-4xl font-bold pb-4 text-center lg:text-start font-manrope">
                                Get started with{" "}
                                <span className="text-[#410EAD] font-manrope">
                                    Tanθ
                                </span>
                            </h2>
                            <h4 className="text-[#59587C] text-sm lg:text-lg text-center lg:text-start md:px-14 lg:px-0 xl:pr-60 pb-2">
                                Our award and recognitions validate that when businesses think about expanding into mobile
                                segment, we are the top Web3 and blockchain app development company in US they turn to.
                            </h4>
                            <div className="flex flex-wrap justify-center lg:justify-start xl:pr-60">
                                {buttonData?.map((button) => (
                                    <button
                                        key={button?.id}
                                        className={cx(
                                            Active === button?.id
                                                ? activeColor
                                                : inActiveColor
                                        )}
                                        onClick={() => {
                                            Warehousing(button?.id);
                                        }}
                                    >
                                        {button?.name}
                                    </button>
                                ))}
                            </div>
                        </div>

                        <div className="container mx-auto lg:mt-0">
                            <div className="border border-[#D7D7D7] rounded-[20px] p-2 lg:p-4">
                                {/* <div className="border border-[#D7D7D7] rounded-[20px]"> */}
                                {/* {warehousingSdk()} */}
                                {Active === 1 && <CodeSnippet fullCode={NFT} />}
                                {Active === 2 && (
                                    <>
                                        <CodeSnippet fullCode={DEFI} />
                                    </>
                                )}
                                {Active === 3 && (
                                    <>
                                        <CodeSnippet
                                            fullCode={WalletDevelopment}
                                        />
                                    </>
                                )}
                                {Active === 4 && (
                                    <>
                                        <CodeSnippet fullCode={UserOps} />
                                    </>
                                )}
                                {Active === 5 && (
                                    <>
                                        <CodeSnippet fullCode={DApps} />
                                    </>
                                )}
                                {Active === 6 && (
                                    <>
                                        <CodeSnippet fullCode={Solana} />
                                    </>
                                )}
                                {Active === 7 && (
                                    <>
                                        <CodeSnippet fullCode={ERC4337} />
                                    </>
                                )}
                                {Active === 8 && (
                                    <>
                                        <CodeSnippet fullCode={ERC6551} />
                                    </>
                                )}
                                {Active === 9 && (
                                    <>
                                        <CodeSnippet fullCode={ERC7702} />
                                    </>
                                )}
                                {Active === 10 && (
                                    <>
                                        <CodeSnippet fullCode={JSONRPC} />
                                    </>
                                )}
                                {Active === 11 && (
                                    <>
                                        <CodeSnippet fullCode={SWAP} />
                                    </>
                                )}
                                {Active === 12 && (
                                    <>
                                        <CodeSnippet fullCode={CrossBridge} />
                                    </>
                                )}
                                {Active === 13 && (
                                    <>
                                        <CodeSnippet fullCode={AIML} />
                                    </>
                                )}
                                {Active === 14 && (
                                    <>
                                        <CodeSnippet fullCode={DEX} />
                                    </>
                                )}
                                {Active === 15 && (
                                    <>
                                        <CodeSnippet fullCode={SPHINICS} />
                                    </>
                                )}
                                {Active === 16 && (
                                    <>
                                        <CodeSnippet fullCode={SHA256} />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default DigitalProductDevelopment;
